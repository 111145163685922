<template>
  <router-view/>
</template>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Teko:wght@300..700&display=swap');
.title {
    font-family: "Teko", Sans-serif;
    font-size: 50px;
    font-weight: 500;
    text-transform: uppercase;
    line-height: 58px;
    letter-spacing: -2.2px;
}
html,
body {
    margin: 0 auto !important;
    padding: 0 !important;
    overflow-x: hidden;
}
section {
    min-width: 90vw!important;
    max-width: 90vw!important;
    margin: 0 auto!important
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
*{
  text-decoration: none;
  text-align: left;
}
.btn-banner {
  background: #007bff;
  color: #fff !important;
  padding: 10px 30px !important;
  border-radius: 99px;
  max-width: 20vw !important;
  min-width: 20vw !important;
  animation: pulse 2s infinite;
  @media only screen and (max-width: 600px) {
    max-width: 70vw !important;
    min-width: 70vw !important;
    margin: 0 auto !important;
  }
}
</style>
